// Disable animation if transitions are disabled

// scss-docs-start progress-keyframes
@if $enable-transitions {
  @keyframes progress-bar-stripes {
    0% { background-position-x: $progress-height; }
  }
}
// scss-docs-end progress-keyframes

@keyframes m-progress {
  0% {
    right: 100%;
    left: -35%;
  }
  60% {
    right: -90%;
    left: 100%;
  }
  100% {
    right: -90%;
    left: 100%;
  }
}

@keyframes m-progress-short {
  0% {
    right: 100%;
    left: -200%;
  }
  60% {
    right: -8%;
    left: 107%;
  }
  100% {
    right: -8%;
    left: 107%;
  }
}

@keyframes m-rainbow {
  0% {
    left: 50%;
    z-index: 100;
    width: 0;
  }
  50% {
    left: 0;
    z-index: 10;
    width: 100%;
  }
  100% {
    left: 0;
    width: 100%;
  }
}

.progress,
.progress-stacked {
  // scss-docs-start progress-css-vars
  --#{$prefix}progress-height: #{$progress-height};
  @include rfs($progress-font-size, --#{$prefix}progress-font-size);
  --#{$prefix}progress-bg: #{$progress-bg};
  --#{$prefix}progress-border-radius: #{$progress-border-radius};
  --#{$prefix}progress-box-shadow: #{$progress-box-shadow};
  --#{$prefix}progress-bar-color: #{$progress-bar-color};
  --#{$prefix}progress-bar-bg: #{$progress-bar-bg};
  --#{$prefix}progress-bar-transition: #{$progress-bar-transition};
  // scss-docs-end progress-css-vars

  position: relative;
  display: flex;
  height: var(--#{$prefix}progress-height);
  overflow: hidden; // force rounded corners by cropping it
  @include font-size(var(--#{$prefix}progress-font-size));
  background-color: var(--#{$prefix}progress-bg);
  @include border-radius(var(--#{$prefix}progress-border-radius));
  @include box-shadow(var(--#{$prefix}progress-box-shadow));

  .progress-bar-indeterminate {
    &::before,
    &::after {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      content: "";
      background-color: inherit;
    }

    &::before {
      animation: m-progress 2.1s cubic-bezier(.65, .815, .735, .395) infinite;
      will-change: left, right;
    }
    &::after {
      animation: m-progress-short 2.1s cubic-bezier(.165, .84, .44, 1) infinite;
      animation-delay: 1.15s;
      will-change: left, right;
    }
  }

  .color-bar {
    position: absolute;
    left: 50%;
    display: inline;
    width: 0;
    height: 100%;
    text-align: center;
    content: "";
  }
}

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: var(--#{$prefix}progress-bar-color);
  text-align: center;
  white-space: nowrap;
  background-color: var(--#{$prefix}progress-bar-bg);
  @include transition(var(--#{$prefix}progress-bar-transition));
}

.progress-bar-striped {
  @include gradient-striped();
  background-size: var(--#{$prefix}progress-height) var(--#{$prefix}progress-height);
}

.progress-stacked > .progress {
  overflow: visible;
}

.progress-stacked > .progress > .progress-bar {
  width: 100%;
}

@if $enable-transitions {
  .progress-bar-animated {
    animation: $progress-bar-animation-timing progress-bar-stripes;

    @if $enable-reduced-motion {
      @media (prefers-reduced-motion: reduce) {
        animation: none;
      }
    }
  }
}
