.m-shape-container {
  position: relative;
  display: inline-block;
  overflow: hidden;
  vertical-align: middle;
  //visibility: hidden;

  *:not(.ripple-surface):not(.btn-fab),
  *:not(.ripple-surface):not(.btn-fab):hover {
    @include border-radius(0);
    outline: none; // stylelint-disable declaration-property-value-disallowed-list
    box-shadow: none;
  }

  .angle-top-left,
  .angle-top-right,
  .angle-bottom-left,
  .angle-bottom-right {
    position: absolute;
    z-index: 10;
    width: 8px;
    height: 8px;
    background: #fff;
  }

  .angle-top-left {
    top: -4px;
    left: -4px;
    transform: rotate(-45deg);
  }

  .angle-top-right {
    top: -4px;
    right: -4px;
    transform: rotate(45deg);
  }

  .angle-bottom-left {
    bottom: -4px;
    left: -4px;
    transform: rotate(-135deg);
  }

  .angle-bottom-right {
    right: -4px;
    bottom: -4px;
    transform: rotate(135deg);
  }
}
