// scss-docs-start table-variant
@mixin table-variant($state) {
  .table-#{$state} {
    --#{$prefix}table-color: var(--#{$prefix}#{$state}-emphasis);
    --#{$prefix}table-bg: var(--#{$prefix}#{$state}-subtle);
    --#{$prefix}table-border-color: var(--#{$prefix}#{$state}-border-subtle);
    --#{$prefix}table-striped-bg: var(--#{$prefix}#{$state}-subtle-hover);
    --#{$prefix}table-striped-color: var(--#{$prefix}#{$state}-emphasis);
    --#{$prefix}table-active-bg: var(--#{$prefix}#{$state}-subtle-active);
    --#{$prefix}table-active-color: var(--#{$prefix}#{$state}-emphasis);
    --#{$prefix}table-hover-bg: var(--#{$prefix}#{$state}-subtle-hover);
    --#{$prefix}table-hover-color: var(--#{$prefix}#{$state}-emphasis-hover);

    color: var(--#{$prefix}table-color);
    border-color: var(--#{$prefix}table-border-color);
  }
}
// scss-docs-end table-variant
