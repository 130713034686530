@mixin bsBanner($file) {
  /*!
   * Material Style #{$file} v3.1.1 (https://materialstyle.github.io/)
   * Copyright 2018-2023 Neeraj Kumar Das
   * Licensed under MIT (https://github.com/materialstyle/materialstyle/blob/main/LICENSE)
   * This a fork of Bootstrap: Initial license below
   *
   * Bootstrap #{$file} v5.3.2 (https://getbootstrap.com/)
   * Copyright 2011-2023 The Bootstrap Authors
   * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
   */
}
